/**
 * @summary
 */
import { useContext, useEffect } from 'react';
import { initializeSession, initSessionFormValues } from 'utils/sessionUtils';
import { getUserProfile } from 'app-requests/triadmsRequests';
import {
  FLOODLIGHT_EVENTS,
  getTriadPixel,
  trackFloodlightActivity,
  trackPageView,
  trackSecondsOnPage,
  trackUserInitialScroll,
} from 'utils/trackingFunctions';
import { LogError, configureSessionForLogging } from 'utils/logging';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { QUERY_PARAMS, REGION_COOKIE_NAME } from 'consts';
import {
  appendAndEvalRawHtml,
  getSidCookieOrQueryParams,
  setCookie,
} from 'utils/analyticsHelpers';
import { getSearchEngineCookies } from 'utils/getSearchEngine';

export default function SessionInitializer({
  query,
  updateUserData,
  questionnaire = {},
  siteMeta: {
    siteName,
    schoolCode,
    googleTagManagerSiteId,
    floodlightActivityValues,
    isPersonalized,
    geoLocation,
  },
  configEnv,
  environmentVariables = {},
}) {
  const {
    taxonomyValues,
    sessionInfo: { hasScrolled },
    actions: { updateSessionInfo },
  } = useContext(GlobalContext);

  // this will get current user info, such as questionnaire progress
  function handleReturningUser() {
    return getUserProfile(taxonomyValues).then((response) => {
      const questionAnswers = response.profile;
      if (questionnaire.steps && questionAnswers.length) {
        const sessionValues = initSessionFormValues(
          questionAnswers,
          questionnaire
        );
        updateUserData({
          sessionFormValues: sessionValues.formValues,
          linkedSessionFormValues: sessionValues.linkedSessionFormValues,
        });
      }
    });
  }

  useEffect(() => {
    if (!schoolCode) {
      return;
    }

    configureSessionForLogging({
      geoLocation,
      isPersonalized,
      googleTagManagerSiteId,
      schoolCode,
      developmentEnvironment: configEnv,
      searchEngineCookies: getSearchEngineCookies(),
      environmentVariables,
    });
    trackSecondsOnPage();
    trackSecondsOnPage(10);

    if (isPersonalized) {
      setCookie(REGION_COOKIE_NAME, geoLocation.region);
    }

    // this will create user session if needed
    initializeSession(query, { schoolCode, taxonomyValues })
      .then(({ sessionId, pageViewId, floodLightActivityFilters }) => {
        handleReturningUser().catch(() => /* not fatal */ null);

        configureSessionForLogging({
          sessionId,
          floodLightActivityFilters,
          sid: getSidCookieOrQueryParams(),
        });
        updateSessionInfo({
          userSessionId: sessionId,
          pageViewId,
          floodLightActivityFilters,
        });

        // For Triad Conversion Tracking
        getTriadPixel({
          profile: query[QUERY_PARAMS.PROFILE_GUID] || '',
          type: 'landing',
        })
          .then(appendAndEvalRawHtml)
          .catch(() => /* not fatal */ null);

        trackFloodlightActivity(
          FLOODLIGHT_EVENTS.PAGE_VIEW,
          floodlightActivityValues,
          floodLightActivityFilters
        );
      })
      .catch((error) => {
        LogError(`Session Error: ${error.message}`);
      })
      .finally(() => {
        trackPageView({
          questionnaireVariant: questionnaire.variant,
          siteName,
          schoolCode,
          googleTagManagerSiteId,
        });
      });
  }, []);

  // The logic to tell us if a user has scrolled
  useEffect(() => {
    const threshold = 100;
    let initialized = false;
    let currentYPosition;

    const onScroll = () => {
      if (initialized) {
        currentYPosition =
          currentYPosition >= 0 ? currentYPosition : window.scrollY;
        const scrollDepth = Math.abs(currentYPosition - window.scrollY);
        const hasScrolled_ = scrollDepth > threshold;
        updateSessionInfo({ hasScrolled: hasScrolled_ });
        hasScrolled_ && trackUserInitialScroll();
      }
      initialized = true;
    };

    !hasScrolled &&
      window.addEventListener('scroll', onScroll, { passive: true });
    return () =>
      !hasScrolled &&
      window.removeEventListener('scroll', onScroll, { passive: true });
  }, [hasScrolled]);

  // We may need to return our datalayer here for Google Tag Manager
  return null;
}

SessionInitializer.propTypes = {};
