export const QUERY_STRING = 'triad_qs_';

// Note the _PARAM and _SLUG suffix. these can map to a URI or query param
export const QUERY_PARAMS = {
  A_B_TEST_PARAM: 'variant',
  HEADLINE_PARAM: 'headline',
  SHARED_EDU_ID: 'sharedEduProfileId',
  SHARED_SESSION_ID: 'sharedSessionId',
  // TODO: [T1-11092] Remove this and its implementation as we are no longer referencing it in GTM
  ORIGIN_SCHOOL_CODE: 'triad_osc',
  CATEGORY_GUID_PARAM: 'category_guid',
  PARENT_CAT_GUID_PARAM: 'parent_category_guid',
  DEGREE_GUID_PARAM: 'degree_level_guid',
  PROGRAM_GUID_PARAM: 'program_guid',
  PROGRAM_TITLE_PARAM: 'program_title',
  FILTER_KEY_PARAM: 'filter_key',
  IMPRESSION_GUID_PARAM: 'impression_guid',
  PROFILE_GUID: 'sid', // TODO: [T1-9735] Update the name of this. It's not technically a profile GUID it's a publisher code GUID
  DEGREE_SLUG: 'degree',
  LANDING_SLUG: 'degree',
  CONTENT_SLUG: 'pages',
  LANDING_PAGE_TEMPLATE_SLUG: 'template',
};

export const DEFAULT_A_B_TEST = '';

// Note this is fragile and should only be used if we can't have some info come back from the question bank
export enum QUESTION_IDS {
  SUBJECT_0F_INTEREST = 2,
  DEGREE = 13,
  ZIP = 15,
  EMAIL = 21,
  STREET = 22,
  FIRST_NAME = 19,
  LAST_NAME = 20,
  PRIMARY_PHONE = 25,
  SECONDARY_PHONE = 27, // This ID doesn't exist in the question bank, it's hardcoded for the FE implementation
  TCPA_DISCLOSURE = 28,
  CONCENTRATIONS = 29,
  CITY = 35,
  CLICK_PORTAL_DEGREES = 40,
  CLICK_PORTAL_SUBJECTS = 41,
  CLICK_PORTAL_CONCENTRATIONS = 42,
  MICRO_PORTAL_DYNAMIC_TCPA = 45,
  MICRO_PORTAL_ADDITIONAL_LEADS = 46,
  MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION = 47,
  GOOGLE_ADDRESS_LOOKUP = 53,
}

/* NOTE: if we add custom field names that do not exist in question bank
 * we need to add them to the getValidFormValues function in the formValuesUtils file
 */
export enum FIELD_NAMES {
  CITY = 'City',
  STATE = 'State',
  PRIMARY_PHONE = 'Primary Phone',
  PRIMARY_PHONE_TYPE = 'Primary Phone Type',
  SECONDARY_PHONE_TYPE = 'Secondary Phone Type',
  SECONDARY_PHONE = 'Secondary Phone',
  EMAIL = 'Email Address',
  ZIP = 'ZIP Code',
  FIRST_NAME = 'First Name',
  CLICK_PORTAL_DEGREES = 'click-portal-degrees',
  CLICK_PORTAL_SUBJECTS = 'click-portal-subjects',
  CLICK_PORTAL_CONCENTRATIONS = 'click-portal-concentrations',
  MICRO_PORTAL_ADDITIONAL_LEADS = 'micro-portal-additional-leads',
  GOOGLE_ADDRESS_LOOKUP = 'google_address_lookup',
  STREET_ADDRESS = 'Street Address',
}

export const CLICK_PORTAL_SEARCH_FILTERS = {
  DEGREES: FIELD_NAMES.CLICK_PORTAL_DEGREES,
  SUBJECTS: FIELD_NAMES.CLICK_PORTAL_SUBJECTS,
  CONCENTRATIONS: FIELD_NAMES.CLICK_PORTAL_CONCENTRATIONS,
  FILTER_KEY: 'filterKey',
};

export enum VALIDATION_TYPES {
  ZIP = 'ZIP',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  STREET = 'STREET',
  SCHOOL_SKIP_OR_SUBMIT = 'SCHOOL_SKIP_OR_SUBMIT',
  GOOGLE_ADDRESS_LOOKUP = 'FULL_ADDRESS_SEARCH',
}

export const DEFAULT_SELECT_VALUE = {
  value: 'select_one',
  label: 'Select One',
};

export const PROGRAM_GROUP_NAMES = {
  MAIN: 'main',
  RELATED: 'related',
  OTHER: 'other',
};

export const AB_TEST_TYPE_MAP = {
  QUESTIONNAIRE: 'questionnaire',
};

export const FORM_ANIMATION_SPEED = 500;

// This is used for the browser navigation control of the form wizard
export const HISTORY_PAGE_ID = `${Date.now()}_${Math.random()}`;

/*
 * If we want other questions in the future to be affected by parentCategory and category
 * values add it to this array. then the consumer of this function can figure out what to do with it.
 */
export const parentCategoryFieldIds = [2];
export const categoryFieldIds = [29];
export const programFieldIds = [23];

// Default Redirect Location
export const defaultRedirect = '/default/landing';
export const clickPortalRedirect = '/portal/search';
export const notFoundRedirect = '/404';

// Local Storage Keys
export const LOCAL_STORAGE_KEYS = {};

export enum BREAKPOINTS {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export const BREAKPOINT_MAP: Record<
  BREAKPOINTS,
  { label: string; width: number }
> = {
  MOBILE: { label: 'mobile', width: 767 },
  TABLET: { label: 'tablet', width: 999 },
  DESKTOP: { label: 'desktop', width: 1279 },
};

// TODO: [T1-9422] Inventory all DEPENDENCIES and update codebase to use constants
export enum DEPENDENCIES {
  DYNAMIC_OPTIONS = 'DYNAMIC_OPTIONS',
  CLEAR_VALUES = 'CLEAR_VALUES',
  VISIBILITY = 'VISIBILITY',
  DYNAMIC_VISIBILITY = 'DYNAMIC_VISIBILITY',
}

// IF CHANGING see setValueIfOnlyOneOption function for possible to skip cases
export enum SKIP_REASON_CODES {
  PROGRAM_SELECTION = 'program_selection', // When user click learn more
  TAXONOMY = 'taxonomy', // on a vertical page, all but the default landing
  SINGLE_VALUE = 'single value', // when a question only has one option
  USER_SKIPPED = 'user skipped', // when a question is skipped by the user
}

// Route map to content type
export const ROUTE_TO_CONTENT_TYPE_MAP = {
  '/[degree]/landing': 'landing',
  '/content/[pages]': 'content',
  '/[degree]/landing/form': 'form',
  '/portal/search': 'clickPortalSearch',
};

// Site Types
export const SITE_TYPE = {
  MICRO_SITE: 'microSite',
  CLICK_PORTAL: 'clickPortal',
  MICRO_PORTAL: 'microPortal',
};

export enum SCHOOL_IMPRESSION_TYPES {
  /* creates the cache and returns the set of guids. returns ImpressionKey so we can request impressions separably */
  CP_REQUEST_IMPRESSIONS = 'ClickPortalWithCache',
  /* creates cache and does not returns the set of guids. returns ImpressionKey so we can request impressions separably */
  CP_REQUEST_IMPRESSIONS_CACHE_KEY = 'ClickPortalCacheOnly',
  /* no impression caching */
  MP_REQUEST_IMPRESSIONS = 'ClickPortal',
}

export const RATING_TOOLTIP_TEXT =
  "Highestgrades.com's ratings are determined by our editorial team. The scoring formula takes into account academic quality, academic outcomes, affordability and the school's online offerings. Our goal with the school ratings is to objectively assess relative quality.";

export const MICRO_PORTAL_PRIMARY_FORM_TID = 'MICRO_PORTAL_PRIMARY_FORM_TID';
export const MICRO_PORTAL_SECONDARY_FORM_TID =
  'MICRO_PORTAL_SECONDARY_FORM_TID';

export const REGION_COOKIE_NAME = 'triad_region';

/**
 * @readonly
 * @enum {string}
 */
export enum FormFieldValidationStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  PENDING = 'pending',
}

export enum BACKEND_ENDPOINTS {
  MUDD1 = 'https://api.tmsmudd.com/api/',
  MUDD2 = 'https://api02.tmsmudd.com/api/',
  MUDD3 = 'https://api03.tmsmudd.com/api/',
  MUDD4 = 'https://api04.tmsmudd.com/api/',
  KHAN1 = 'https://api.tmskhan.com/api/',
  KHAN2 = 'https://api02.tmskhan.com/api/',
  KHAN3 = 'https://api03.tmskhan.com/api/',
  KHAN4 = 'https://api04.tmskhan.com/api/',
  PROD = 'https://expertstudent.com/api/',
}

export enum MICRO_PORTAL_ENDPOINTS {
  QUESTION_OPTIONS_ENDPOINT = '/microportal/mpQuestionOption',
  SCHOOL_OPTIONS_ENDPOINT = '/microportal/GetMPSchoolListings',
  LOG_PROGRESS_ENDPOINT = '/microportal/MPQuestionLog',
}

export enum TRUSTED_FORM_CONTACT_METHODS {
  name = 'consent-grantor-name',
  phone = 'consent-grantor-phone',
  email = 'consent-grantor-email',
  address = 'consent-grantor-address',
}

export const QUESTION_ID_TO_CONTACT_TYPE_MAP = {
  [QUESTION_IDS.FIRST_NAME]: TRUSTED_FORM_CONTACT_METHODS.name,
  [QUESTION_IDS.LAST_NAME]: TRUSTED_FORM_CONTACT_METHODS.name,
  [QUESTION_IDS.PRIMARY_PHONE]: TRUSTED_FORM_CONTACT_METHODS.phone,
  [QUESTION_IDS.SECONDARY_PHONE]: TRUSTED_FORM_CONTACT_METHODS.phone,
  [QUESTION_IDS.EMAIL]: TRUSTED_FORM_CONTACT_METHODS.email,
  [QUESTION_IDS.STREET]: TRUSTED_FORM_CONTACT_METHODS.address,
  [QUESTION_IDS.CITY]: TRUSTED_FORM_CONTACT_METHODS.address,
  [QUESTION_IDS.ZIP]: TRUSTED_FORM_CONTACT_METHODS.address,
};
