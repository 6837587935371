export enum AVAILABLE_COMPONENTS {
  ADMISSIONS = 'admissions',
  BASIC_FORM_WIZARD = 'DEFAULT_FORM',
  MULTI_QUESTION_FORM = 'MULTI_QUESTION_FORM',
  MULTI_QUESTION_FORM_FULL_PAGE = 'MULTI_QUESTION_FORM_FULL_PAGE',
  BILLBOARD = 'billBoard',
  CLICK_PORTAL_FOOTER = 'CLICK_PORTAL_FOOTER',
  CLICK_PORTAL_FORM_WIZARD = 'CLICK_PORTAL_FORM_WIZARD',
  MICRO_PORTAL_FORM_WIZARD = 'MICRO_PORTAL_FORM_WIZARD',
  MICRO_PORTAL_FORM_WIZARD_FULL_PAGE = 'MICRO_PORTAL_FORM_WIZARD_FULL_PAGE',
  ADDITIONAL_LEADS_FORM = 'ADDITIONAL_LEADS_FORM',
  CLICK_PORTAL_SIDEBAR_FORM_WIZARD = 'CLICK_PORTAL_SIDEBAR_FORM_WIZARD',
  CLICK_PORTAL_HEADER = 'CLICK_PORTAL_HEADER',
  DEFAULT_FOOTER = 'DEFAULT_FOOTER',
  DEFAULT_HEADER = 'DEFAULT_HEADER',
  FEATURE_BLOCKS = 'featureBlocks',
  FULL_PAGE_FORM_WIZARD = 'FULL_PAGE_FORM_WIZARD',
  LANDING_PAGE_HEADER_ALT = 'LANDING_PAGE_HEADER_ALT',
  LANDING_PAGE_HEADER = 'LANDING_PAGE_HEADER',
  PAGE_HERO_V1 = 'hero',
  PROGRAM_INFO = 'programInfo',
  PROGRAM_SEARCH = 'programs',
  SLIDER = 'slider',
  USER_CONTENT = 'userContent',
  FAQ = 'faq',
}

// ---------- Form Field Components ----------//
export enum FIELD_TYPES {
  ADDITIONAL_SCHOOLS_DISCLAIMER = 'ADDITIONAL_SCHOOLS_DISCLAIMER',
  BOOLEAN = 'BOOLEAN',
  DISCLAIMER = 'DISCLAIMER',
  DYNAMIC_DISCLAIMER = 'DYNAMIC_DISCLAIMER',
  MULTI_SCHOOL_SELECT = 'MULTI_SCHOOL_SELECT',
  EMAIL = 'EMAIL',
  GROUPED_BUTTON_SELECT = 'GROUPED_BUTTON_SELECT',
  INPUT = 'INPUT',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  RADIO_BUTTONS = 'RADIO_BUTTONS',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  ZIPCODE = 'ZIPCODE',
  SELECTION_CARD = 'SELECTION_CARD',
  GOOGLE_ADDRESS_LOOKUP = 'GOOGLE_ADDRESS_LOOKUP',
}

interface FieldOptions {
  type: FIELD_TYPES;
  hasOptions: boolean;
  isSkippable: boolean;
  shouldShowCta: boolean;
  shouldSubmitOnEnter: boolean;
  allowMultiValues: boolean;
}

export const AVAILABLE_FIELDS: Record<FIELD_TYPES, FieldOptions> = {
  [FIELD_TYPES.ADDITIONAL_SCHOOLS_DISCLAIMER]: {
    type: FIELD_TYPES.ADDITIONAL_SCHOOLS_DISCLAIMER,
    hasOptions: false,
    isSkippable: false,
    shouldShowCta: false,
    shouldSubmitOnEnter: false,
    allowMultiValues: false,
  },
  [FIELD_TYPES.INPUT]: {
    type: FIELD_TYPES.INPUT,
    shouldSubmitOnEnter: true,
    hasOptions: false,
    isSkippable: false,
    allowMultiValues: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.EMAIL]: {
    type: FIELD_TYPES.EMAIL,
    shouldSubmitOnEnter: true,
    hasOptions: false,
    isSkippable: false,
    allowMultiValues: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.NUMBER]: {
    type: FIELD_TYPES.NUMBER,
    shouldSubmitOnEnter: true,
    hasOptions: false,
    isSkippable: false,
    allowMultiValues: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.SELECT]: {
    type: FIELD_TYPES.SELECT,
    hasOptions: true,
    isSkippable: true,
    allowMultiValues: false,
    shouldSubmitOnEnter: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.RADIO]: {
    type: FIELD_TYPES.RADIO,
    hasOptions: true,
    isSkippable: true,
    allowMultiValues: false,
    shouldSubmitOnEnter: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.RADIO_BUTTONS]: {
    type: FIELD_TYPES.RADIO_BUTTONS,
    hasOptions: true,
    isSkippable: true,
    shouldShowCta: true,
    allowMultiValues: false,
    shouldSubmitOnEnter: false,
  },
  [FIELD_TYPES.BOOLEAN]: {
    type: FIELD_TYPES.BOOLEAN,
    hasOptions: true,
    isSkippable: true,
    shouldShowCta: true,
    allowMultiValues: false,
    shouldSubmitOnEnter: false,
  },
  [FIELD_TYPES.MULTISELECT]: {
    type: FIELD_TYPES.MULTISELECT,
    hasOptions: true,
    allowMultiValues: true,
    shouldSubmitOnEnter: false,
    shouldShowCta: false,
    isSkippable: false,
  },
  [FIELD_TYPES.GROUPED_BUTTON_SELECT]: {
    type: FIELD_TYPES.GROUPED_BUTTON_SELECT,
    hasOptions: true,
    isSkippable: true,
    allowMultiValues: false,
    shouldSubmitOnEnter: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.PHONE]: {
    type: FIELD_TYPES.PHONE,
    shouldSubmitOnEnter: true,
    hasOptions: true,
    isSkippable: false,
    allowMultiValues: false,
    shouldShowCta: false,
  },
  [FIELD_TYPES.DISCLAIMER]: {
    type: FIELD_TYPES.DISCLAIMER,
    isSkippable: true,
    shouldShowCta: false,
    shouldSubmitOnEnter: false,
    hasOptions: false,
    allowMultiValues: false,
  },
  [FIELD_TYPES.DYNAMIC_DISCLAIMER]: {
    type: FIELD_TYPES.DYNAMIC_DISCLAIMER,
    hasOptions: true,
    isSkippable: false,
    shouldShowCta: false,
    shouldSubmitOnEnter: false,
    allowMultiValues: true,
  },
  [FIELD_TYPES.MULTI_SCHOOL_SELECT]: {
    type: FIELD_TYPES.MULTI_SCHOOL_SELECT,
    hasOptions: true,
    /*
      we cant use this to auto submit the step because this question has many parts so it
      will try to auto submit as the user is selecting more schools
    */
    isSkippable: false,
    shouldShowCta: false,
    shouldSubmitOnEnter: false,
    allowMultiValues: true,
  },
  [FIELD_TYPES.SELECTION_CARD]: {
    type: FIELD_TYPES.SELECTION_CARD,
    hasOptions: true,
    shouldShowCta: false,
    shouldSubmitOnEnter: false,
    isSkippable: false,
    allowMultiValues: false,
  },
  [FIELD_TYPES.ZIPCODE]: {
    type: FIELD_TYPES.ZIPCODE,
    shouldSubmitOnEnter: true,
    shouldShowCta: false,
    hasOptions: false,
    isSkippable: false,
    allowMultiValues: false,
  },
  [FIELD_TYPES.GOOGLE_ADDRESS_LOOKUP]: {
    type: FIELD_TYPES.GOOGLE_ADDRESS_LOOKUP,
    shouldSubmitOnEnter: true,
    shouldShowCta: false,
    hasOptions: false,
    isSkippable: false,
    allowMultiValues: false,
  },
};

const allAvailableFields = Object.keys(AVAILABLE_FIELDS) as FIELD_TYPES[];

// If one of these question is on a step the step will not auto submit when complete
export const nonSkipableFieldsTypes = allAvailableFields
  .filter((fieldType) => !AVAILABLE_FIELDS[fieldType].isSkippable)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);

export const nonCtaEnabledFields = allAvailableFields
  .filter((fieldType) => !AVAILABLE_FIELDS[fieldType].shouldShowCta)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);

export const ctaEnabledFields = allAvailableFields
  .filter((fieldType) => AVAILABLE_FIELDS[fieldType].shouldShowCta)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);

export const optionEnabledFields = allAvailableFields
  .filter((fieldType) => AVAILABLE_FIELDS[fieldType].hasOptions)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);

export const enterSubmitEnabledFields = allAvailableFields
  .filter((fieldType) => AVAILABLE_FIELDS[fieldType].shouldSubmitOnEnter)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);

export const multiValueEnabledFields = allAvailableFields
  .filter((fieldType) => AVAILABLE_FIELDS[fieldType].allowMultiValues)
  .map((fieldType) => AVAILABLE_FIELDS[fieldType].type);
