import isBrowser from 'utils/isBrowser';
import { TrackJS } from 'utils/trackjs-isomorphic';
import { getNewRelicAgent } from './getNewRelicAgent';

interface InitializeLoggingOptions {
  configEnv: string;
  buildId: string;
  revisionId: string;
  userId?: string;
  triadBackendDomain?: string;
  WPHost?: string;
}

/**
 * @summary Initialize Tracking Configs for either Server or Browser
 */
export function initializeLogging(
  trackingId: string,
  opts: InitializeLoggingOptions
): void {
  const {
    configEnv,
    buildId,
    revisionId,
    userId = '',
    triadBackendDomain,
    WPHost,
  } = opts;
  const application = `triadms_${configEnv}`;

  getNewRelicAgent().then((agent) => {
    if (agent && agent.setApplicationVersion) {
      agent.setApplicationVersion(buildId);
    }
  });

  // show environments in console on non-prod environments
  if (isBrowser() && configEnv !== 'production') {
    // eslint-disable-next-line no-console
    console.table({
      env: configEnv,
      BE: triadBackendDomain,
      WP: WPHost,
    });
  }

  if (TrackJS.isInstalled()) {
    return;
  }

  TrackJS.install({
    token: trackingId,
    application,
    console: {
      watch: ['info', 'warn', 'error'],
      display: configEnv !== 'production',
    },
    version: revisionId,
  });

  // Note this may be Nodejs Or Browser API, both return bool as per specs
  TrackJS.configure({ userId });

  // This will be attached to every error
  TrackJS.addMetadata('configEnv', configEnv);
  TrackJS.addMetadata('buildId', buildId);

  if (isBrowser()) {
    TrackJS.addMetadata('domain', window.location.host);
    TrackJS.addMetadata('asPath', window.location.pathname);
  }
}
