import { getFormSelectionObjectsFromQuery } from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import { getUserSessionId } from 'utils/analyticsHelpers';
import isBrowser from 'utils/isBrowser';
import processTestRequest from './processTestRequest';
import handleUserDataUpdate from './utils/handleUserDataUpdate';

const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
const UPDATE_GLOBAL_MODAL = 'UPDATE_GLOBAL_MODAL';
const SET_AB_TEST = 'SET_AB_TEST';
const UPDATE_GLOBAL_FLAGS = 'UPDATE_GLOBAL_FLAGS';
const UPDATE_GLOBAL_REFS = 'UPDATE_GLOBAL_REFS';
const SET_CSS_CUSTOM_PROPS = 'SET_CSS_CUSTOM_PROPS';
const SET_ACTIVE_MODAL_ID = 'SET_ACTIVE_MODAL_ID';
export const UPDATE_CLICK_PORTAL = 'UPDATE_CLICK_PORTAL';
const UPDATE_SESSION_INFO = 'UPDATE_SESSION_INFO';
const UPDATE_MICRO_PORTAL = 'UPDATE_MICRO_PORTAL';

export const initialState = {
  sessionInfo: {
    userSessionId: isBrowser() ? getUserSessionId() : null,
    hasScrolled: false,
    pageViewId: null, // this is never stored in cookie as we get fresh ID each page load
    floodLightActivityFilters: {
      trackingSchoolCode: '',
      propertyOrigin: '',
      campaignType: '',
      isClickUser: false,
    },
  },
  resumeModal: {
    isOpen: false,
  },
  activeModalId: null,
  flags: {
    hasFormSidebar: false,
    hasStickyHeader: false,
    isFirstStep: true,
    isFormDirty: false,
    navIsOpen: false,
    isLoading: false,
    hasExpandedCard: false,
  },
  windowSize: {
    height: null,
    width: null,
    currentBreakpoint: 'mobile',
  },
  cssCustomProps: {},
  refs: {},
  userData: {
    degreeSelection: null,
    parentCategorySelection: null,
    applicationLink: null,
    categorySelection: null,
    programOfInterest: null,
    linkedSessionFormValues: {},
  },
  abTests: {},
  clickPortal: {
    title: '',
    subTitle: '',
    currentSelection: {},
    results: [],
    error: '',
    isLoading: true,
    shouldShowSteps: false,
    // todo: update these with values from backend API
    secondaryNavItems: [
      {
        label: 'Business Administration',
        url: '/portal/search?filter_key=business-administration',
      },
      {
        label: 'Psychology',
        url: '/portal/search?filter_key=psychology',
      },
      {
        label: 'Counseling',
        url: '/portal/search?filter_key=counseling',
      },
      {
        label: 'Criminal Justice',
        url: '/portal/search?filter_key=criminal-justice',
      },
    ],
  },
  formSettings: {},
  microPortal: {
    primaryFormSubmitPollingHelper: null, // request for the first form
    secondaryFormSubmitPollingHelper: null, // request for the 2nd form
    additionalSchoolResultsPromise: null, // form configs of the 2nd form
    leadSubmitResults: null, // results for the 1nd form
    additionalSchoolResults: null, // results for the 2nd form
  },
};

export function updateUserDataAct(dispatch, update, taxonomyValues) {
  return dispatch({
    type: UPDATE_USER_DATA,
    update,
    taxonomyValues,
  });
}

export function setResumeModalPropsAct(dispatch, update) {
  return dispatch({
    type: UPDATE_GLOBAL_MODAL,
    update,
  });
}

export function setActiveModalIdAct(dispatch, modalId) {
  return dispatch({
    type: SET_ACTIVE_MODAL_ID,
    modalId,
  });
}

export function updateGlobalFlagsAct(dispatch, flags) {
  return dispatch({
    type: UPDATE_GLOBAL_FLAGS,
    flags,
  });
}

export function updateGlobalRefsAct(dispatch, ref) {
  return dispatch({
    type: UPDATE_GLOBAL_REFS,
    ref,
  });
}

export function setCssCustomPropsAct(dispatch, propName, propValue) {
  return dispatch({
    type: SET_CSS_CUSTOM_PROPS,
    propName,
    propValue,
  });
}

export async function setAbTestAct(dispatch, payload, { siteMeta }) {
  const testData = await processTestRequest(payload, siteMeta);
  return dispatch({
    type: SET_AB_TEST,
    testData,
  });
}

export function updateClickPortalData(dispatch, update, microSiteTaxonomyMap) {
  return dispatch({
    type: UPDATE_CLICK_PORTAL,
    update,
    microSiteTaxonomyMap,
  });
}

export function updateMicroPortalData(dispatch, update) {
  return dispatch({
    type: UPDATE_MICRO_PORTAL,
    update,
  });
}

export function updateSessionInfo(dispatch, update) {
  return dispatch({
    type: UPDATE_SESSION_INFO,
    update,
  });
}

export const getActions = (
  dispatch,
  { siteMeta, taxonomyValues, microSiteTaxonomyMap }
) => ({
  setAbTest: (payload) => setAbTestAct(dispatch, payload, { siteMeta }),
  setResumeModalProps: (update) => setResumeModalPropsAct(dispatch, update),
  setActiveModalId: (modalId) => setActiveModalIdAct(dispatch, modalId),
  updateGlobalFlags: (update) => updateGlobalFlagsAct(dispatch, update),
  updateGlobalRefs: (update) => updateGlobalRefsAct(dispatch, update),
  updateClickPortalData: (update) =>
    updateClickPortalData(dispatch, update, microSiteTaxonomyMap),
  updateMicroPortalData: (update) => updateMicroPortalData(dispatch, update),
  updateSessionInfo: (update) => updateSessionInfo(dispatch, update),
  updateUserData: (update) =>
    updateUserDataAct(dispatch, update, taxonomyValues),
  setCssCustomProps: (propName, propValue) =>
    setCssCustomPropsAct(dispatch, propName, propValue),
});

export function reducer(state, action) {
  function mergeState(stateAttr, actionAttr) {
    return {
      ...state,
      [stateAttr]: { ...state[stateAttr], ...action[actionAttr] },
    };
  }

  switch (action.type) {
    case UPDATE_GLOBAL_REFS:
      return mergeState('refs', 'ref');
    case SET_AB_TEST:
      return mergeState('abTests', 'testData');
    case UPDATE_GLOBAL_FLAGS:
      return mergeState('flags', 'flags');
    case UPDATE_CLICK_PORTAL: {
      const updatedState = mergeState('clickPortal', 'update');
      if (action.update.filterKeyDerivedValues?.hasDerivedValues) {
        updatedState.clickPortal.currentSelection =
          getFormSelectionObjectsFromQuery(
            action.update.filterKeyDerivedValues,
            action.microSiteTaxonomyMap || state.microSiteTaxonomyMap
          );
      }
      return updatedState;
    }
    case UPDATE_MICRO_PORTAL:
      return mergeState('microPortal', 'update');
    case UPDATE_SESSION_INFO:
      return mergeState('sessionInfo', 'update');
    case UPDATE_USER_DATA:
      return handleUserDataUpdate(state, action);
    case UPDATE_GLOBAL_MODAL:
      return { ...state, resumeModal: action.update };
    case SET_ACTIVE_MODAL_ID:
      return {
        ...state,
        activeModalId: action.modalId,
        navIsOpen: !!action.modalId,
      };
    case SET_CSS_CUSTOM_PROPS:
      return {
        ...state,
        cssCustomProps: {
          ...state.cssCustomProps,
          [action.propName]: action.propValue,
        },
      };
    default:
      throw new Error('Unknown Action on Global Context');
  }
}
