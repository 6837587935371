import get from 'lodash/get';
import requestPropsTransform from 'utils/wordpressPropsTransform';

// TODO: write a test for this
export default function parseWpQuestionnaires(questionnaires, opt) {
  const { formSettings, questionBankMap, schoolCode } = opt;

  return questionnaires.reduce((nextQuestionnaires, questionnaire) => {
    const currentQuestionnaires = nextQuestionnaires;
    const variant = get(questionnaire, 'questionnaireName');
    const id = get(questionnaire, 'questionnaireId');
    const {
      defaultQuestionnaireId,
      fullPageQuestionnaireId,
      clickPortalQuestionnaireId,
    } = formSettings;
    const output = requestPropsTransform(questionnaire, {
      questionBankMap,
      schoolCode,
      variant,
    });

    if (id === defaultQuestionnaireId) {
      currentQuestionnaires.default = output;
    }

    if (id === fullPageQuestionnaireId) {
      currentQuestionnaires.fullPage = output;
    }

    if (id === clickPortalQuestionnaireId) {
      currentQuestionnaires.clickPortal = output;
    }

    return currentQuestionnaires;
  }, {});
}
