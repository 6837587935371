import { BrowserOrServerNewRelicAgent } from 'types';

let newrelicAgent: BrowserOrServerNewRelicAgent | null = null;

const ERROR_MESSAGE_TO_IGNORE = [
  'Script error',
  'Unhandled Promise Rejection: Object Not Found Matching Id:',
  'Request has been terminated Possible causes',
];
/**
 * Singleton instance of the New Relic agent that works in both browser and server environments.
 * This is initialized asynchronously when the newrelic-isomorphic module is loaded.
 */
export function getNewRelicAgent(): Promise<BrowserOrServerNewRelicAgent | null> {
  if (newrelicAgent) {
    return Promise.resolve(newrelicAgent);
  }

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (newrelicAgent) {
        clearInterval(interval);
        resolve(newrelicAgent);
      }
    }, 300);

    // Add timeout after 3 seconds
    setTimeout(() => {
      clearInterval(interval);
      resolve(null);
    }, 3000);
  });
}

/**
 * Determines whether an error should be tracked.
 */
export function shouldTrackError(error: Error): boolean {
  for (let i = 0; i < ERROR_MESSAGE_TO_IGNORE.length; i += 1) {
    if (error.message.includes(ERROR_MESSAGE_TO_IGNORE[i])) {
      return false;
    }
  }

  return true;
}

import('utils/newrelic-isomorphic')
  .then((module) => {
    newrelicAgent = module.default as BrowserOrServerNewRelicAgent;
    if (newrelicAgent?.setErrorHandler) {
      newrelicAgent.setErrorHandler((err) => {
        // Returning false will always track this error
        return !shouldTrackError(err);
      });
    }
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error(`Error loading newrelic: ${error.message}`);
  });
